<template>
  <v-form
      class="jc-form"
      ref="form"
      v-model="valid"
      @submit.prevent="$emit('submit', $event)"
      autocomplete="off"
    >
     <div class="jc-form__btns">
        <jc-btn
          :disabled="!valid"
          type="submit"
        >
        Create
        </jc-btn>
        <jc-btn
          @click="clear"
          color="secondary"
        >
          Clear
        </jc-btn>
        <jc-btn
          color="secondary"
          @click="back"
        >
          Back
        </jc-btn>
     </div>
     <slot />
     <div class="jc-form__btns">
        <jc-btn
          :disabled="!valid && processing"
          type="submit"
        >
        Create
        </jc-btn>
        <jc-btn
          @click="clear"
          color="secondary"
        >
          Clear
        </jc-btn>
        <jc-btn
          color="secondary"
          @click="back"
        >
          Back
        </jc-btn>
     </div>
    </v-form>
</template>

<script>
import JcBtn from '@/components/JcBtn.vue'
export default {
  components: { JcBtn },

  name: 'JcForm',

  props: {
    processing: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    valid: false
  }),

  methods: {
    submit (e) {
      if (this.$refs.form.validate()) {
        this.$emit('submit', e)
      } else {
        console.log('Form is not valid')
      }
    },

    clear () {
      this.$refs.form.reset()
    },

    back () {
      this.$router.back()
    }
  }
}
</script>

<style lang="stylus">
.jc-form
  &__btns
    display: flex
    align-items: center
    justify-content: space-around
    margin: 16px 0

    /deep/ .jc-btn
      width: 100%
</style>
