<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="8">
          <v-card class="jc-notify-card">
            <v-card-title>Successfully created a ticket</v-card-title>
            <v-card-text>
             Thank you for submitting your request.
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { rules } from '@/form'

export default {
  name: 'CreatedTicketNotification',

  data: () => ({
    rules: rules,
    valid: false,
    email: null
  })
}
</script>

<style lang="stylus" scoped>
.jc-notify-card
  &__btns
    display: flex
    justify-content: flex-end
    align-items: center

  &__optional-btns
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center

    /deep/ .jc-btn
      font-size: 14px !important
</style>
