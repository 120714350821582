const API_HOST = process.env.VUE_APP_API_BASE_URL // 'http://localhost:9080/api'

export default {
  namespaced: true,

  state: {
    fields: []
  },

  getters: {
    fields: (state) => state.fields,
    getFieldItems: (state) => (id) => state.fields.find(field => field.id === id)?.items || [],
    isItemExist: (_, getters) => (id, value) => {
      if (!value) return false

      return getters
        .getFieldItems(id)
        .some(item => item.value.toLowerCase() === value.toLowerCase())
    }
  },

  mutations: {
    SET_FIELDS (state, data) {
      state.fields = data
    },

    ADD_FIELD (state, { id, value }) {
      const field = state.fields.find(field => field.id === id)
      const items = [...field.items, {
        guid: '',
        value,
        label: value
      }]
      state.fields = state.fields.map(item => item.id === id ? { ...item, items } : item)
    }
  },

  actions: {
    getFieldsDefaultValues ({ commit }) {
      const url = `${API_HOST}/mdm/forms`
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }

      return fetch(url, options)
        .then(response => response.json())
        .then(response => {
          if (response.state !== 'error') {
            const fields = []
            for (const idx in response.data) {
              fields.push({
                id: idx,
                items: response.data[idx]
              })
            }
            commit('SET_FIELDS', fields)

            return response.data
          } else {
            commit('SET_FIELDS', [])
            throw new Error(response.message)
          }
        })
    },
    addField ({ commit, getters }, { id, value }) {
      if (!value) return

      if (getters.isItemExist(id, value)) return

      commit('ADD_FIELD', { id, value })

      return value
    }
  }
}
