<template>
  <v-btn class="jc-btn" :type="type" :class="classes" v-on="$listeners" v-bind="$attrs">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'JcBtn',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    classes () {
      return [
        `jc-btn--${this.color.toLowerCase()}`,
        this.disabled ? 'jc-btn--disabled' : ''
      ]
    }
  }
}
</script>

<style lang="stylus">
.jc-btn
  font-weight: bold !important
  text-transform: uppercase
  border-radius: 0
  box-shadow: none
  transition: .3s background-color !important
  cursor: pointer

  &::before
    content: none

  &--primary
    background-color: var(--c-secondary) !important
    color: var(--c-white) !important
    box-shadow 0 2px 2px rgba(0, 0, 0, .3) !important

  &--secondary
    background-color: transparent !important
    color: var(--c-primary) !important

    &:hover
      background-color: #fafafa !important

  &--disabled
    opacity: .1
    pointer-events: none
</style>
