<template>
  <v-dialog
    v-model="dialog"
    class="jc-ticket-edit"
    width="1000px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <jc-btn class="mr-4" v-bind="attrs" v-on="on">Edit ticket</jc-btn>
    </template>

    <div class="jc-ticket-edit__content pa-4">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="8" order="1" order-sm="0">
            <div class="jc-ticket-edit__title">Edit ticket</div>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="4">
            <div class="d-flex align-center justify-space-between justify-sm-end">
              <v-icon
                @click="dialog = false"
              >
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-form v-model="valid" @submit.prevent="submit">
          <v-row>
            <v-col>
                <template v-for="field in forms[formType]">
                  <component
                    :key="field.id"
                    :is="field.field"
                    :label="field.label"
                    v-model="$data[field.id]"
                    :items="getFieldItems(field.fieldItems)"
                    item-text="label"
                    :required="field.required"
                    :rules="field.rules"
                    :hint="field.hint"
                    :multiple="field.multiple"
                    persistent-hint
                    :autocomplete="field.field === 'jc-password' ? 'new-password' : ''"
                  />
                </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <jc-btn :disabled="!valid" type="submit" :loading="loading">Save</jc-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
import JcBtn from '@/components/JcBtn'
import { forms } from '@/form'
import { VTextField, VAutocomplete, VCheckbox, VTextarea } from 'vuetify/lib'
import JcDatePicker from '@/components/JcDatePicker'
import JcPassword from '@/components/JcPassword'
import JcFilePicker from '@/components/JcFilePicker'
import { mapGetters, mapActions } from 'vuex'
import { addRequiredAsterisk } from '@/form/helpers'
import JcCodearea from '@/components/JcCodearea'

export default {
  name: 'JcTicketEdit',
  /* eslint-disable vue/no-unused-components */
  components: { JcBtn, VTextField, VAutocomplete, VCheckbox, JcFilePicker, JcDatePicker, JcPassword, VTextarea, JcCodearea },
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      valid: true,
      forms,
      loading: false,
      ...this.getTicketData()
    }
  },

  computed: {
    ...mapGetters('formFields', ['getFieldItems']),
    formType () {
      return this.ticket.type ? this.ticket.type.toLowerCase().replace(' ', '_') : ''
    }
  },

  mounted () {
    addRequiredAsterisk()
  },

  updated () {
    addRequiredAsterisk()
  },

  methods: {
    ...mapActions('tickets', ['editTicket', 'attachFileToTicket']),
    getTicketData () {
      if (!forms[this.ticket.type]) return

      return forms[this.ticket.type].reduce((obj, field) => {
        const detail = this?.ticket?.details?.find(detail => detail.id === field.id)
        obj[field.id] = detail?.value ?? field.defaultValue

        if (detail?.id === 'attachments') {
          obj[field.id] = this.ticket.attachments ?? field.defaultValue
        }
        return obj
      }, {
        type: this.ticket.type
      })
    },
    submit () {
      const { valid, forms, dialog, loading, ...data } = this.$data
      const editedTicket = Object.fromEntries(Object.entries(data).filter(item => item[1] != null))
      this.loading = true

      this.editTicket({ key: this.ticket.key, ticket: editedTicket }).then((ticket) => {
        if (this.$data.attachments && this.$data.attachments.length) {
          // Upload file
          return this.attachFileToTicket({
            ticket: ticket,
            attachment: this.$data.attachments
          }).catch(() => {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: 'Editing ticket succeeded, but the attachment upload failed'
            })
          })
        }
      }).then(() => {
        this.dialog = false
        this.loading = false
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Editing ticket succeeded'
        })
      }).catch((e) => {
        this.loading = false
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Editing ticket failed'
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-ticket-edit
  &__content
    height: 100%
    width: 100%
    background-color: var(--c-white)

  &__title
    font-size: 30px
    font-weight: bold
    color: var(--c-primary)
</style>
