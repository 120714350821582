<template>
  <div class="jc-icon-btn" :class="{ 'jc-icon-btn--flip': isFlipped }" v-on="$listeners" v-bind="$attrs">
    <div class="jc-icon-btn__inner">
      <div class="jc-icon-btn__front">
        <v-icon v-if="icon" size="90" color="primary">{{ icon }}</v-icon>
        <div class="jc-icon-btn__text">
          <slot />
        </div>
        <v-icon
          v-if="flip"
          class="jc-icon-btn__flip-btn pa-4"
          @click.prevent="flipCard"
        >
          mdi-information-outline
        </v-icon>
      </div>
      <div class="jc-icon-btn__back">
        <slot name="desc" />
        <v-icon
          v-if="flip"
          class="jc-icon-btn__flip-btn pa-4"
          @click.prevent="flipCard"
        >
          mdi-orbit-variant
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JcIconBtn',
  props: {
    icon: {
      type: String,
      default: ''
    },
    flip: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isFlipped: false
  }),
  methods: {
    flipCard () {
      this.isFlipped = !this.isFlipped
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-icon-btn
  background-color: transparent
  perspective: 1000px
  min-width: 275px
  min-height: 275px
  width: 100%
  height: 100%
  cursor: pointer

  &__inner
    transform-style: preserve-3d
    position: relative
    width: 100%
    height: 100%
    transition: .6s transform .2s, .3s box-shadow

    .jc-icon-btn.jc-icon-btn--flip &
      transform: rotateY(180deg)

  &__front, &__back
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    backface-visibility: hidden
    background-color: var(--c-white) !important
    padding: 32px
    border-radius: 15px
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)

  &__front
    display: inline-flex !important
    flex-direction: column
    align-items: center
    justify-content: center
    transition: .3s box-shadow

    .jc-icon-btn:not(.jc-icon-btn--flip) &:hover
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3)

  &__back
    display: flex
    justify-content: center
    align-items: center
    transform: rotateY(180deg)

  &__text
    color: var(--c-primary)
    font-weight: bold
    font-size: 30px
    text-transform: uppercase
    text-align: center

  &__flip-btn
    position: absolute
    top: 0
    right: 0
    color: var(--c-primary)
    cursor: pointer

    &::after
      content: none
</style>
