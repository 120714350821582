const API_HOST = process.env.VUE_APP_API_BASE_URL // 'http://localhost:9080/api'

const state = () => ({
  user: null,
  token: null,
  isActive: false,
  isAdmin: false
})

// getters
const getters = {
  user (state) {
    return state.user
  },

  isAuthenticated (state) {
    return state.user && state.token && state.isActive
  },

  isActive (state) {
    return state.isActive
  },

  isAdmin (state) {
    return state.isActive && state.isAdmin
  },

  bearerToken (state) {
    return state.token ? `Bearer ${state.token}` : ''
  }
}

// actions
const actions = {
  loginWithPassword ({ commit }, { email, password }) {
    if (!email && !password) {
      throw new Error('Email and password cant be empty')
    }
    const url = `${API_HOST}/session/authenticate-with-password`
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({
        email,
        password
      })
    }

    return fetch(url, options)
      .then(response => response.json())
      .then(response => {
        if (response.state !== 'error') {
          commit('setSession', response.data)
          return response.data
        } else {
          commit('setSession', null)
          throw new Error(response.message)
        }
      })
  },

  loginWithToken ({ commit }, { token }) {
    if (!token) {
      throw new Error('Token cant be empty')
    }
    const url = `${API_HOST}/session/authenticate-with-token`
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({
        token
      })
    }

    return fetch(url, options)
      .then(response => {
        if (!response.ok) {
          if (response.status === 424) {
            response.json().then(res => commit('setSession', res.data))
          }
          throw new Error(response.status)
        }
        return response.json()
      })
      .then(response => {
        commit('setSession', response.data)
        return response.data
      })
  },

  logout ({ commit }) {
    commit('setSession', null)
  }
}

// mutations
const mutations = {
  setUser (state, user) {
    state.user = user
  },

  setSession (state, session) {
    state.user = session?.user ?? null
    state.token = session?.token ?? null
    state.isActive = session?.is_active ?? false
    state.isAdmin = session?.is_admin ?? false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
