<template>
  <v-app>
    <jc-header />
    <v-main>
      <div v-if="isAuthenticated" class="d-flex justify-end primary--text mr-5 mt-2 font-italic">logged in {{ user.firstname }} {{ user.lastname }}</div>
      <router-view class="pt-6"/>
      <notifications position="bottom right" :duration="3000" />
    </v-main>
    <v-footer class="d-flex justify-center">Version: v{{version}}</v-footer>
  </v-app>
</template>

<script>

import JcHeader from '@/components/JcHeader'
import { mapActions, mapGetters } from 'vuex'
import { addRequiredAsterisk } from '@/form/helpers'

export default {
  name: 'App',

  components: { JcHeader },

  data: () => ({
    version: process.env.VUE_APP_VERSION
  }),

  computed: {
    ...mapGetters('session', ['user', 'isAuthenticated'])
  },

  mounted () {
    this.getFieldsDefaultValues()
    addRequiredAsterisk()
  },

  updated () {
    addRequiredAsterisk()
  },

  methods: {
    ...mapActions('formFields', ['getFieldsDefaultValues'])
  }
}
</script>
