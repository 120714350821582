<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="8">
          <v-card v-if="step == $options.STEP_ENTER_EMAIL" class="jc-reset-card">
            <v-card-title>Reset password</v-card-title>
            <v-card-subtitle>Enter your email below</v-card-subtitle>
            <v-card-text>
              <v-form v-model="valid" @submit.prevent="onResetClick">
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  :rules="[rules.required, rules.email]"
                  required
                />
                <div class="jc-reset-card__btns">
                  <jc-btn type="submit" :disabled="!valid || inProgress" :loading="inProgress">Reset</jc-btn>
                  <jc-btn color="secondary" :disabled="inProgress" @click="$router.back()">Back</jc-btn>
                </div>
              </v-form>
            </v-card-text>
            <v-card-text class="jc-reset-card__optional-btns">
              <router-link :to="{ name: 'Register' }">
                <jc-btn :disabled="inProgress" color="secondary">Create a new account</jc-btn>
              </router-link>
            </v-card-text>
          </v-card>
          <v-card v-else-if="step == $options.STEP_INFO" class="jc-reset-card">
            <v-card-title>Reset password</v-card-title>
            <v-card-text>
              You should get an email in the next seconds where you have to confirm the password reset.
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import JcBtn from '@/components/JcBtn.vue'
import { rules } from '@/form'

const STEP_ENTER_EMAIL = 1
const STEP_INFO = 2

export default {
  components: { JcBtn },

  name: 'ForgotPassword',

  STEP_ENTER_EMAIL,
  STEP_INFO,

  data: () => ({
    inProgress: false,
    rules: rules,
    valid: false,
    email: null,
    step: STEP_ENTER_EMAIL
  }),

  methods: {
    ...mapActions('account', ['forgotPassword']),

    onResetClick () {
      if (!this.valid) return
      this.inProgress = true

      this.forgotPassword({ email: this.email }).then(() => {
        this.inProgress = false
        this.step = STEP_INFO
      }).catch((err) => {
        this.inProgress = false
        this.$notify({
          type: 'error',
          title: 'Reset failed',
          text: err.message ?? err
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-reset-card
  &__btns
    display: flex
    justify-content: flex-end
    align-items: center

  &__optional-btns
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center

    /deep/ .jc-btn
      font-size: 14px !important
</style>
