import download from 'downloadjs'

const API_HOST = process.env.VUE_APP_API_BASE_URL // 'http://localhost:9080/api'

const ticketToBackendFormat = (ticket) => {
  if (Array.isArray(ticket?.website_features)) {
    ticket.website_features = ticket.website_features.join(',')
  }

  if (typeof ticket?.adobe_analytics === 'boolean') {
    ticket.adobe_analytics = ticket.adobe_analytics ? 'Yes' : 'No'
  }

  if (typeof ticket?.adobe_target === 'boolean') {
    ticket.adobe_target = ticket.adobe_target ? 'Yes' : 'No'
  }

  return ticket
}

const ticketToFrontendFormat = (ticket) => {
  ticket.details = ticket?.details?.map(detail => {
    if (detail.id === 'website_features' && detail.value) {
      detail.value = detail.value.split(',')
    }

    if ((detail.id === 'adobe_analytics' || detail.id === 'adobe_target') && detail.value) {
      detail.value = detail.value === 'Yes'
    }
    return detail
  })
  return ticket
}

const state = () => ({
  tickets: []
})

// getters
const getters = {
  allTickets (state) {
    return state.tickets
  }
}

// actions
const actions = {
  addNewTicket ({ commit, rootGetters }, ticket) {
    if (!ticket) {
      throw new Error('Ticket cant be empty')
    }

    ticket = ticketToBackendFormat(ticket)

    const url = `${API_HOST}/tickets`
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      },
      body: JSON.stringify(ticket)
    }

    return fetch(url, options)
      .then(response => response.json())
      .then(response => {
        if (response.state !== 'error') {
          commit('addNewTicket', response.data)
          return response.data
        } else {
          commit('addNewTicket', null)
          throw new Error(response.message)
        }
      })
  },

  attachFileToTicket ({ commit, rootGetters }, { ticket, attachment }) {
    if (!attachment || !attachment.length) {
      throw new Error('Attachment cant be empty')
    }

    const formData = new FormData()
    formData.append('attachment', attachment[0])

    const url = `${API_HOST}/tickets/${ticket.key}/attach`
    const options = {
      method: 'PUT',
      headers: {
        Authorization: rootGetters['session/bearerToken']
      },
      body: formData
    }

    return fetch(url, options)
  },

  downloadAttachment ({ commit, rootGetters }, { ticket, fileSpecs }) {
    if (!ticket || !fileSpecs) {
      throw new Error('ticket and/or fileSpecs cant be empty')
    }

    const url = `${API_HOST}/tickets/${ticket.key}/attachment/${fileSpecs.id}`
    const options = {
      method: 'GET',
      headers: {
        Authorization: rootGetters['session/bearerToken']
      }
    }

    return fetch(url, options).then((resp) => {
      return resp.blob()
    }).then((blob) => {
      download(blob, fileSpecs.name)
    })
  },

  getTickets ({ commit, rootGetters }, filter) {
    let filterParam = ''

    if (filter && filter.length) {
      filterParam = '?filter=' + filter.join('')
    }

    const url = `${API_HOST}/tickets${filterParam}`
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      }
    }

    return fetch(url, options)
      .then(response => response.json())
      .then(response => {
        response.data = response.data.map(ticket => ticketToFrontendFormat(ticket))
        return response
      })
      .then(response => {
        if (response.state !== 'error') {
          commit('setTickets', response.data)
          return response.data
        } else {
          commit('setTickets', null)
          throw new Error(response.message)
        }
      })
  },

  deleteTicket ({ commit, rootGetters }, ticket) {
    if (!ticket) {
      throw new Error('Ticket cant be empty')
    }
    const url = `${API_HOST}/tickets/${ticket.guid}`
    const options = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      },
      body: JSON.stringify(ticket)
    }

    return fetch(url, options)
      .then(response => {
        if (response.status === 204) {
          commit('deleteTicket', ticket)
        } else {
          throw new Error(response.message)
        }
      })
  },

  addNewComment ({ commit, rootGetters }, { ticket, comment }) {
    if (!comment || comment === '') {
      throw new Error('Text cant be empty')
    }
    const url = `${API_HOST}/tickets/${ticket.key}/comment`
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      },
      body: JSON.stringify({ comment })
    }

    return fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          const data = response.json()
          throw Error(data.message)
        }
        return response
      })
      .then(() => {
        commit('addComment', { ticket, comment })
      })
  },

  editTicket ({ commit, rootGetters }, { key, ticket }) {
    if (!ticket) {
      throw new Error('Ticket cant be empty')
    }

    ticket = ticketToBackendFormat(ticket)

    const url = `${API_HOST}/tickets/${key}`
    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      },
      body: JSON.stringify(ticket)
    }

    return fetch(url, options)
      .then(response => response.json())
      .then(response => {
        if (response.state !== 'error') {
          commit('editTicket', response.data)
          return response.data
        } else {
          throw new Error(response.message)
        }
      })
  }
}

// mutations
const mutations = {
  addNewTicket (state, ticket) {
    state.tickets = [ticket, ...state.tickets]
  },

  setTickets (state, tickets) {
    state.tickets = tickets
  },

  editTicket (state, ticket) {
    state.tickets = state.tickets.map(item => item.key === ticket.key ? ticket : item)
  },

  addComment (state, { ticket, comment }) {
    const currentTicket = state.tickets.find(item => item.key === ticket.key)
    if (currentTicket) {
      const newComment = {
        body: comment,
        date: new Date(),
        author: { display_name: 'You' }
      }
      currentTicket.comments = currentTicket.comments ? [...currentTicket.comments, newComment] : [newComment]
      state.tickets = state.tickets.map(item => item.key === currentTicket.key ? currentTicket : item)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
