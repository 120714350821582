<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container class="jc-intro">
      <v-row>
        <v-col>
          <v-img class="jc-intro__logo" src="@/assets/images/ah-logo.png" alt="AH Logo" />
          <div class="jc-intro__title mb-4">AH Global Digital Website Tagging</div>
          <div class="jc-intro__text mb-4">Your account is either not yet activated or you don't have enough permissions to see this site.</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Forbidden'
}
</script>

<style lang="stylus" scoped>
.jc-intro
  &__logo
    max-width: 300px

  &__title
    font-size: 36px
    color: var(--c-primary)
    font-weight: bold
</style>
