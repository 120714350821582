<template>
  <v-input
    ref="code"
    v-model="code"
    v-on="$listeners"
    v-bind="$attrs"
    class="jc-codearea"
  >
    <prism-editor class="jc-codearea__editor" v-model="code" :highlight="highlighter" line-numbers />
  </v-input>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css'

export default {
  name: 'JcCodearea',
  components: { PrismEditor },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    code: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  mounted () {
    if (this.$attrs.required) {
      this.$el.querySelector('label').innerHTML += ' <span style="color: red">*</span>'
    }
  },

  methods: {
    highlighter (code) {
      return highlight(code, languages.js)
    }
  }
}
</script>

<style lang="stylus">
.jc-codearea
  margin-top: 10px

  &__editor
    background: #2d2d2d;
    color: #ccc;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace
    font-size: 14px
    line-height: 1.5
    padding: 5px

    .prism-editor__textarea:focus
      outline: none

  .v-input
    &__slot
      flex-wrap: wrap

  .v-label
    transform-origin: top left
    transform: scale(.75)
    pointer-events: auto
</style>
