<template>
  <v-file-input
    v-model="files"
    prepend-icon=""
    append-icon="$file"
    multiple
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'JcFilePicker',
  props: {
    value: {
      type: Array,
      default: null
    }
  },
  computed: {
    files: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>

</style>
