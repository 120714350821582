<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col
          cols="12"
          sm="8"
        >
          <v-card class="jc-login-card">
            <v-card-title>Register</v-card-title>
            <v-card-text>
              <v-form v-model="valid">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="newAccount.firstname"
                          :rules="[rules.required]"
                          label="First Name"
                          required
                        />
                        <v-text-field
                          v-model="newAccount.lastname"
                          :rules="[rules.required]"
                          label="Last Name"
                          required
                        />
                        <v-text-field
                          v-model="newAccount.email"
                          :rules="[rules.required, rules.email]"
                          label="Email"
                          required
                        />
                        <jc-captcha
                          v-model="newAccount.captcha"
                          :rules="[rules.required]"
                          label="Captcha"
                          required
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          v-model="newAccount.country"
                          :rules="[rules.required]"
                          label="Country"
                          clearable
                          required
                          item-text="label"
                          :items="getFieldItems('countries')"
                        />
                        <jc-password
                          v-if="!skipPassword"
                          v-model="newAccount.password"
                          label="Password"
                          :rules="[rules.required, rules.password]"
                          required
                          autocomplete="off"
                        />
                        <jc-password
                          v-if="!skipPassword"
                          v-model="confirmPassword"
                          label="Confirm Password"
                          :rules="[rules.required, rules.password, rules.match(newAccount.password)]"
                          required
                          autocomplete="off"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <div class="d-flex justify-center">
                    <jc-btn
                      @click.stop="onRegisterClick"
                      :disabled="!valid || inProgress"
                      :loading="inProgress"
                    >Register</jc-btn>
                    <jc-btn
                      color="secondary"
                      @click="$router.back()"
                      :disabled="inProgress"
                    >Back</jc-btn>
                  </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { rules } from '@/form'
import { mapActions, mapGetters } from 'vuex'
import JcBtn from '@/components/JcBtn'
import JcPassword from '@/components/JcPassword'
import JcCaptcha from '@/components/JcCaptcha'

export default {
  name: 'Register',

  components: { JcBtn, JcPassword, JcCaptcha },

  data: () => ({
    rules: rules,
    errorMsg: '',
    valid: false,
    inProgress: false,
    confirmPassword: null,
    newAccount: {}
  }),

  created () {
    this.newAccount = { ...this.user } ?? {}
  },

  computed: {
    ...mapGetters('formFields', ['getFieldItems']),
    ...mapGetters('session', ['isActive', 'user']),
    ...mapGetters('account', ['captcha']),

    skipPassword () {
      return !!this.user
    }
  },

  methods: {
    ...mapActions('account', ['registerAccount', 'getCaptcha']),

    onRegisterClick () {
      this.inProgress = true
      const { ts, token } = this.captcha
      const account = { ...this.newAccount, ts, token }
      this.registerAccount({ account }).then(() => {
        this.inProgress = false
        if (this.isActive) {
          this.$router.push('/')
        } else {
          this.$router.push('/account-created')
        }
      }).catch((err) => {
        this.inProgress = false
        this.getCaptcha().then(() => {
          this.newAccount = { ...this.newAccount, captcha: '' }
        })
        this.$notify({
          type: 'error',
          title: 'Registration failed',
          text: err.message ?? err
        })
      })
    }
  }
}
</script>

<style>

</style>
