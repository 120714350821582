<template>
  <div
    ref="captcha"
    class="jc-captcha"
  >
  <div class="jc-captcha__wrapper">
    <img :src="captcha.image" alt="Captcha" class="jc-captcha__img">
    <v-icon class="jc-captcha__icon" @click="reload">mdi-reload</v-icon>
  </div>
  <v-text-field
    v-model="text"
    v-on="$listeners"
    v-bind="$attrs"
    class="jc-captcha__input"
  />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'JcCaptcha',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  created () {
    this.getCaptcha()
  },
  computed: {
    ...mapGetters('account', ['captcha']),
    text: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions('account', ['getCaptcha']),
    reload () {
      this.getCaptcha()
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-captcha
  &__wrapper
    display: flex
    align-items: flex-start

  &__img
    margin-right: 15px
</style>
