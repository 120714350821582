<template>
  <v-autocomplete
    ref="autocomplete"
    v-on="$listeners"
    v-bind="$attrs"
    @update:search-input="(val) => { value = val }"
    class="jc-autocomplete"
  >
    <template #append-item v-if="!isItemExist(fieldItems, value)">
      <jc-btn
        class="mx-4"
        @click.prevent="addAndFocusField"
      >
        Add {{value}} as new item
      </jc-btn>
    </template>
  </v-autocomplete>
</template>

<script>
import JcBtn from './JcBtn'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'JaAutocomplete',
  components: { JcBtn },

  props: ['fieldItems'],

  data () {
    return {
      value: ''
    }
  },

  computed: {
    ...mapGetters('formFields', ['isItemExist'])
  },

  mounted () {
    if (this.$attrs.required) {
      this.$el.querySelector('label').innerHTML += ' <span style="color: red">*</span>'
    }
  },

  methods: {
    ...mapActions('formFields', ['addField']),
    async addAndFocusField () {
      const value = await this.addField({ id: this.fieldItems, value: this.value })
      if (value) {
        this.$refs.autocomplete.selectItem(value)
      }
    }
  }
}
</script>
