<template>
  <v-dialog
    v-model="dialog"
    class="jc-ticket-details"
    width="1000px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <jc-ticket :ticket="ticket" v-bind="attrs" v-on="on" />
    </template>

    <div class="jc-ticket-details__content pa-4">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="8" order="1" order-sm="0">
            <div class="jc-ticket-details__tag">
              <v-icon color="primary" size="24">{{ icons[ticket.type] }}</v-icon>
              <div class="jc-ticket-details__tag-name">{{ ticket.type }}</div>
            </div>
            <div class="jc-ticket-details__id">{{ ticket.key }}</div>
            <div class="jc-ticket-details__status">{{ ticket.status }}</div>
            <div class="jc-ticket-details__title">{{ ticket.summary }}</div>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="4">
            <div class="d-flex align-center justify-space-between justify-sm-end">
              <jc-ticket-edit v-if="hasAccessToEdit" :ticket="ticket" />
              <v-icon
                @click="dialog = false"
              >
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="ticket.description">
          <v-col>
            <div class="jc-ticket-details__section">
              <div class="jc-ticket-details__section-title">Description</div>
              <v-container fluid>
                <v-row>
                  <v-col class="py-1">
                    <div class="jc-ticket-details__text">{{ ticket.description }}</div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="jc-ticket-details__section">
              <div class="jc-ticket-details__section-title">Details</div>
              <v-container fluid>
                <v-row
                  v-for="detail in ticket.details"
                  :key="detail.id"
                >
                  <v-col cols="6" class="py-1 font-weight-bold">{{ detail.label }}</v-col>
                  <v-col cols="6" class="py-1">{{ formattedValue(detail.value) }}</v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="jc-ticket-details__section">
              <div class="jc-ticket-details__section-title">People</div>
              <v-container fluid>
                <v-row>
                  <v-col cols="6" class="py-1 font-weight-bold">Assignee:</v-col>
                  <v-col cols="6" class="py-1">
                    <div class="jc-ticket-details__person" v-if="ticket.assignee.name">
                      <v-icon color="primary" size="24">mdi-account-outline</v-icon>
                      <div class="jc-ticket-details__person-name">{{ ticket.assignee.name }}</div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="py-1 font-weight-bold">Reporter:</v-col>
                  <v-col cols="6" class="py-1">
                    <div class="jc-ticket-details__person" v-if="ticket.reporter.name">
                      <v-icon color="primary" size="24">mdi-account-outline</v-icon>
                      <div class="jc-ticket-details__person-name">{{ ticket.reporter.name }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="jc-ticket-details__section pt-6">
              <div class="jc-ticket-details__section-title ">Attached files</div>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="file in ticket.attachments"
                    :key="file.id"
                    cols="12"
                    class="py-1"
                  >
                    <div class="jc-ticket-details__file">
                      <v-icon color="primary" size="24">mdi-file-outline</v-icon>
                      <div class="jc-ticket-details__file-name pr-2">{{ file.name }}</div>
                      <jc-download-btn :ticket="ticket" :file="file" />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="jc-ticket-detils__section">
              <div class="jc-ticket-details__section-title">Comments</div>
              <v-container fluid>
                <v-row v-if="hasComments">
                  <v-col
                    v-for="comment in ticket.comments"
                    :key="comment.id"
                    cols="12"
                  >
                    <div class="jc-ticket-details__comment">
                      <div class="jc-ticket-details__comment-text">{{ comment.body }}</div>
                      <div class="jc-ticket-details__comment-signature">{{ (new Date(comment.date)).toLocaleString() }}, <span class="primary--text">{{ comment.author.display_name }}</span></div>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    Ticket has no comments yet
                  </v-col>
                </v-row>
                <v-row v-if="hasAccessToEdit">
                  <v-col cols="12">
                    <v-form v-model="valid" @submit.prevent="addComment">
                      <v-textarea
                        v-model="newCommentText"
                        label="Add new comment"
                        placeholder="Write your comment here..."
                        rows="1"
                        ref="textarea"
                        auto-grow
                      />
                      <jc-btn
                        :disabled="newCommentText.length === 0"
                        type="submit"
                        :loading="newCommentLoader"
                      >
                        Add new comment
                      </jc-btn>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JcTicket from '@/components/JcTicket'
import JcTicketEdit from '@/components/JcTicketEdit'
import JcBtn from '@/components/JcBtn'
import { rules, icons } from '@/form'
import JcDownloadBtn from '@/components/JcDownloadBtn.vue'

export default {
  name: 'JcTicketDetail',

  components: { JcTicket, JcTicketEdit, JcBtn, JcDownloadBtn },

  props: {
    ticket: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    newCommentText: '',
    valid: false,
    rules,
    newCommentLoader: false,
    icons
  }),

  computed: {
    ...mapGetters('session', ['user']),

    hasComments () {
      return this.ticket?.comments?.length > 0
    },

    hasAccessToEdit () {
      return this.user && this.ticket && this.ticket.creator === this.user.email
    }
  },

  methods: {
    ...mapActions('tickets', ['addNewComment']),

    addComment () {
      this.newCommentLoader = true
      this.addNewComment({
        ticket: this.ticket,
        comment: this.newCommentText
      }).then(() => {
        this.newCommentText = ''
        this.newCommentLoader = false
      }).catch((err) => {
        this.newCommentLoader = false
        this.$notify({
          type: 'error',
          title: 'Adding comment failed',
          text: err
        })
      })
    },

    formattedValue (val) {
      if (Array.isArray(val)) {
        return val.join(',')
      }

      if (typeof val === 'boolean') {
        return val ? 'Yes' : 'No'
      }

      return val
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-ticket-details
  &__content
    height: 100%
    width: 100%
    background-color: var(--c-white)

  &__tag, &__file, &__person
    display: flex
    align-items: center

  &__tag-name
    text-transform: uppercase
    font-weight: 900
    font-size: 14px
    color: var(--c-secondary)
    margin-left: 5px
    line-height: normal

  &__file-name, &__person-name
    font-size: 14px
    line-height: normal
    margin-left: 5px

  &__id
    font-weight: 500
    font-size: 24px
    color: var(--c-primary)

  &__title
    font-size: 24px
    color: var(--c-black)

  &__status
    display: inline-block
    font-size: 14px
    text-transform: uppercase
    color: var(--c-primary)
    padding-bottom: 1px
    border-bottom: 1px solid var(--c-primary)

  &__section-title
    color: var(--c-primary)
    font-weight: 900
    font-size: 18px

  &__comment
    border-radius: 15px
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)
    padding: 25px
    display: flex
    flex-direction: column

  &__comment-signature
    font-size: 14px
    margin-top: 15px
    align-self: flex-end
</style>
