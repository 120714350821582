import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Forbidden from '@/views/Forbidden'
import CreateTicket from '@/views/CreateTicket'
import Accounts from '@/views/Accounts'
import ListOfTickets from '@/views/ListOfTickets'
import Login from '@/views/Login'
import Register from '@/views/Register'
import ForgotPassword from '@/views/ForgotPassword'
import ResetPassword from '@/views/ResetPassword'
import TicketRequest from '@/views/TicketRequest'
import CreatedTicketNotification from '@/views/CreatedTicketNotification'
import CreatedAccountNotification from '@/views/CreatedAccountNotification'

import {
  TicketTypeNewWebsiteTagging,
  TicketTypeAdvertisingSnippet,
  TicketTypeBugRequest,
  TicketTypeSupportRequest,
  TicketTypeAdobeAnalyticsAccountRequest,
  TicketTypeCIDValueRequest
} from '@/constants'

import Store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/account-created',
    name: 'Account created',
    component: CreatedAccountNotification
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    component: ResetPassword
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    meta: { requiresAuth: false }
  },
  {
    path: '/ticket-created',
    name: 'Ticket created',
    component: CreatedTicketNotification,
    meta: { requiresAuth: true }
  },
  {
    path: '/create-ticket',
    name: 'Create ticket',
    component: CreateTicket,
    meta: { requiresAuth: true }
  },
  {
    path: '/create-ticket/new-website-tagging',
    name: 'New Website Tagging',
    component: TicketRequest,
    props: { type: TicketTypeNewWebsiteTagging },
    meta: { requiresAuth: true }
  },
  {
    path: '/create-ticket/adobe-account-request',
    name: 'Adobe Account Request',
    component: TicketRequest,
    props: { type: TicketTypeAdobeAnalyticsAccountRequest },
    meta: { requiresAuth: true }
  },
  {
    path: '/create-ticket/advertising-snippet',
    name: 'Advertising Snippet',
    component: TicketRequest,
    props: { type: TicketTypeAdvertisingSnippet },
    meta: { requiresAuth: true }
  },
  {
    path: '/create-ticket/bug-request',
    name: 'Bug Request',
    component: TicketRequest,
    props: { type: TicketTypeBugRequest },
    meta: { requiresAuth: true }
  },
  {
    path: '/create-ticket/cid-value-request',
    name: 'CID Value Request',
    component: TicketRequest,
    props: { type: TicketTypeCIDValueRequest },
    meta: { requiresAuth: true }
  },
  {
    path: '/create-ticket/support-request',
    name: 'Support Request',
    component: TicketRequest,
    props: { type: TicketTypeSupportRequest },
    meta: { requiresAuth: true }
  },
  {
    path: '/tickets',
    name: 'List of tickets',
    component: ListOfTickets,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Authentication
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Store.getters['session/isAuthenticated']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!Store.getters['session/isAdmin']) {
          next({
            path: '/forbidden',
            query: { redirect: to.fullPath }
          })
        } else {
          next()
        }
      } else {
        next()
      }
    }
  } else {
    if (Store.getters['session/isAuthenticated']) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  }
})

// Page title
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.name || 'AH GLOBAL DIGITAL WEBSITE TAGGING'
  })
})

export default router
