export const addRequiredAsterisk = () => {
  const inputs = document.querySelectorAll('.v-input [required]')
  inputs.forEach(input => {
    const label = document.querySelector(`label[for="${input.id}"]`)
    const asterix = '<span style="color: red">*</span>'
    if (label.innerHTML.toString().search(/\*/) === -1) {
      label.innerHTML += ` ${asterix}`
    }
  })
}
