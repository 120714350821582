const API_HOST = process.env.VUE_APP_API_BASE_URL // 'http://localhost:9080/api'

const state = () => ({
  countries: [],
  accounts: [],
  captcha: {}
})

// getters
const getters = {
  accounts (state) {
    return state.accounts
  },
  captcha (state) {
    return state.captcha
  }
}

// actions
const actions = {
  getAccounts ({ commit, rootGetters }) {
    const url = `${API_HOST}/admin/accounts`
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      }
    }

    return fetch(url, options)
      .then(response => response.json())
      .then(response => {
        if (response.state !== 'error') {
          commit('setAccounts', response.data)
          return response.data
        } else {
          commit('setAccounts', [])
          throw new Error(response.message)
        }
      })
  },

  registerAccount ({ commit, rootGetters }, { account }) {
    const url = `${API_HOST}/accounts`
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      },
      body: JSON.stringify(account)
    }

    return fetch(url, options)
      .then(response => response.json())
      .then(response => {
        if (response.state !== 'error') {
          commit('session/setSession', response.data, { root: true })
          return response.data
        } else {
          throw new Error(response.message)
        }
      })
  },

  toggle ({ commit, rootGetters }, { account, field }) {
    const url = `${API_HOST}/admin/accounts/${account.guid}/toggle/${field}`
    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: rootGetters['session/bearerToken']
      }
    }

    return fetch(url, options)
      .then(() => {
        commit('toggleField', { account, field })
      })
      .catch(response => {
        const resp = response.json()
        if (resp.state === 'error') {
          throw new Error(response.message)
        }
        throw new Error(response)
      })
  },

  forgotPassword ({ commit, rootGetters }, { email }) {
    const url = `${API_HOST}/accounts/forgot-password`
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ email })
    }

    return fetch(url, options)
  },

  resetPassword ({ commit, rootGetters }, { email, password, token }) {
    const url = `${API_HOST}/accounts/reset-password`
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ email, password, token })
    }

    return fetch(url, options)
  },

  getCaptcha ({ commit }) {
    const url = `${API_HOST}/captcha`
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }

    return fetch(url, options)
      .then(response => response.json())
      .then(response => {
        if (response.state !== 'error') {
          commit('setCaptcha', response.data)
        } else {
          throw new Error(response.message)
        }
      })
  }
}

// mutations
const mutations = {
  setCountries (state, countries) {
    state.countries = countries
  },

  setAccounts (state, accounts) {
    state.accounts = accounts
  },

  toggleField (state, { account, field }) {
    const index = state.accounts.findIndex(item => item.guid === account.guid)
    state.accounts[index][field] = !state.accounts[index][field]
  },

  setCaptcha (state, data) {
    state.captcha = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
