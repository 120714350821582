import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState({
    paths: ['session'],
    storage: window.sessionStorage
  })]
})
