export default {
  required: v => (!!v || (Array.isArray(v) && v.length > 0)) || 'Field is required',
  password: v => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*.,=+_-]).{8,}$/
    return (
      (!v || pattern.test(v)) ||
      'Min. 8 characters with at least one capital letter, a number and a special character.'
    )
  },
  email: v => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return (
      (!v || pattern.test(v)) || 'Incorrect email address'
    )
  },
  match: v => (v2) => v === v2 || 'Field does not match',
  url: v => {
    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
    return (
      (!v || pattern.test(v)) || 'Incorrect URL address'
    )
  }
}
