<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container>
      <v-row>
        <v-spacer />
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
            />
          </v-col>
          <v-spacer />
      </v-row>
      <v-row>
        <v-col>
          <v-tabs
            class="jc-filters"
            v-model="tabIndex"
            show-arrows
            centered
          >
            <v-tab
              v-for="type in types"
              :key="type.id"
            >
              {{ type.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row >
         <v-overlay
          :absolute="true"
          :value="inProgress"
        >
          <v-progress-circular
            v-if="inProgress"
            indeterminate
            color="primary"
            :style="{
              position: 'fixed',
              top: '50vh',
              left: '50vw',
              transform: 'translate(-50%, -50%)'
            }"
          />
        </v-overlay>
        <v-col v-if="searchedTickets && searchedTickets.length" class="jc-ticket-list">
          <jc-ticket-details
            v-for="ticket in searchedTickets"
            :key="ticket.id"
            :ticket="ticket"
          />
        </v-col>
        <v-col v-else class="d-flex justify-center">
          No tickets available
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination
            v-if="pageCount > 1"
            v-model="page"
            :length="pageCount"
            class="mt-4"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JcTicketDetails from '@/components/JcTicketDetails'
// import ticketsResponse from '@/mockups/ticketsResponse'

export default {
  name: 'ListOfTickets',

  components: { JcTicketDetails },

  data () {
    return {
      search: null,
      tabIndex: 0,
      // page: ticketsResponse.page,
      // pageCount: ticketsResponse.page_count,
      // tickets: ticketsResponse.data
      page: 1,
      pageCount: 0,
      inProgress: false,
      absolute: true,
      types: [
        { id: 0, name: 'All', type: 'all' },
        { id: 1, name: 'Website tagging', type: 'website_tagging' },
        { id: 2, name: 'Advertising snippet', type: 'advertising_snippet' },
        { id: 3, name: 'Bug', type: 'bug' },
        { id: 4, name: 'Support', type: 'support' },
        { id: 5, name: 'Adobe Analytics Account', type: 'adobe_analytics_account' },
        { id: 6, name: 'CID Value', type: 'cid_value' }
      ]
    }
  },

  created () {
    this.inProgress = true
    this.getTickets().then(() => {
      this.inProgress = false
    })
  },

  computed: {
    ...mapGetters('tickets', ['allTickets']),
    currentType () {
      const tab = this.types.find(type => type.id === this.tabIndex)
      return tab ? tab.type : 'all'
    },
    filteredTickets () {
      if (this.currentType === 'all') return this.allTickets
      return this.allTickets.filter(ticket => ticket.type === this.currentType)
    },
    searchedTickets () {
      if (!this.search) return this.filteredTickets
      return this.filteredTickets.filter(ticket => {
        return this.searchInObject(ticket, this.search)
      })
    }
  },

  methods: {
    ...mapActions('tickets', ['getTickets']),
    searchInObject (obj, text) {
      return Object.values(obj).findIndex(val => {
        if (!val) return false

        if (Array.isArray(val)) return val.findIndex(item => this.searchInObject(item, text)) !== -1

        if (typeof val === 'object') return this.searchInObject(val, text)

        return val.toString().toLowerCase().search(this.escapeRegExp(text.toLowerCase())) !== -1
      }) !== -1
    },
    escapeRegExp (regex) {
      return regex.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-filters
  width: 100%

.jc-ticket-list
  display: flex
  flex-direction: column
  align-items: center

  /deep/ .jc-ticket
    margin-bottom: 15px
    width: 100%
    min-height 175px
    overflow: hidden
</style>
