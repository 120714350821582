<template>
  <v-text-field
    v-bind="$attrs"
    v-model="password"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    @click:append="showPassword = !showPassword"
  />
</template>

<script>
export default {
  name: 'JcPassword',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    password: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>

</style>
