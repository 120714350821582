<template>
  <v-app-bar app color="primary" class="jc-header">
    <router-link :to="{ name: 'Home' }" class="jc-logo">
      <span class="hidden-sm-and-down">AH GLOBAL DIGITAL WEBSITE TAGGING</span>
      <span class="hidden-md-and-up">AH</span>
    </router-link>
    <v-toolbar-title class="jc-header__title">{{ pageTitle }}</v-toolbar-title>
    <v-spacer />
    <v-toolbar-items class="hidden-sm-and-down">
      <router-link
        v-for="(item, index) in filteredItemsByRole"
        :key="index"
        :to="item.to"
        class="jc-header__item"
      >
        {{item.title}}
      </router-link>
      <div
        v-if="isActive"
        class="jc-header__item"
        @click="clickLogout"
      >Logout</div>
      <div
        v-else
        class="jc-header__item"
        @click="$router.push('/login')"
      >Login</div>
    </v-toolbar-items>
    <v-menu class="hidden-md-and-up">
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
          v-bind="attrs"
          v-on="on"
          class="jc-header__toggle hidden-md-and-up"
        />
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in filteredItemsByRole"
          :key="index"
        >
          <router-link :to="item.to">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </router-link>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            v-if="isActive"
            @click="clickLogout"
          >Logout</v-list-item-title>
          <v-list-item-title
            v-else
            @click="$router.push('/login')"
          >Login</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const ROLE_AUTHENTICATED = 'authenticated'
const ROLE_ADMIN = 'admin'

export default {
  name: 'JcHeader',

  ROLE_AUTHENTICATED,
  ROLE_ADMIN,

  data: () => ({
    items: [
      { title: 'Create ticket', to: { name: 'Create ticket' }, role: ROLE_AUTHENTICATED },
      { title: 'List of tickets', to: { name: 'List of tickets' }, role: ROLE_AUTHENTICATED },
      { title: 'Accounts', to: { name: 'Accounts' }, role: ROLE_ADMIN }
    ]
  }),

  computed: {
    ...mapGetters('session', ['isActive', 'isAdmin']),

    filteredItemsByRole () {
      return this.items.filter((item) => this.userHasRole(item.role))
    },

    pageTitle () {
      return this.$route.name
    }
  },

  methods: {
    ...mapActions('session', ['logout']),

    clickLogout () {
      this.logout().then(() => {
        this.$router.push('/login')
      })
    },

    userHasRole (role) {
      if (role === ROLE_AUTHENTICATED) {
        return this.isActive
      } else if (role === ROLE_ADMIN) {
        return this.isAdmin
      }
      return false
    }
  }
}
</script>

<style lang="stylus" scope>
.jc-logo
  height: calc(100% - 20px)
  padding: 0 16px
  margin: 10px 0
  display: flex
  justify-content: center
  align-items: center
  color: var(--c-white) !important
  text-decoration: none
  font-weight: normal
  text-transform: uppercase
  border-right: 1px solid rgba(#fff, .1)
  transition: .3s background-color

.jc-header
  background-image: var(--g-primary)

  > .v-toolbar__content
    padding: 0

  &__title
    padding: 0 16px
    text-transform: uppercase
    color: var(--c-white)
    font-size: 14px !important

  &__toggle
    color: var(--c-white) !important
    padding: 0 16px !important
    margin: 0 !important

  &__item
    color: var(--c-white) !important
    text-transform: uppercase
    font-weight 500
    display: flex
    justify-content: center
    align-items: center
    padding: 0 16px
    margin-top: 4px
    height: calc(100% - 4px)
    cursor: pointer
    transition: .3s background-color, .3s color

    &:hover,
    &.router-link-exact-active,
    &.router-link-active
      background-color: var(--c-white) !important
      color: var(--c-primary) !important
</style>
