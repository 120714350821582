<template>
  <div class="jc-ticket" v-on="$listeners" v-bind="$attrs">
    <div class="jc-ticket__col-1">
      <div class="jc-ticket__tag">
        <v-icon color="primary" size="24">{{ icons[ticket.type] }}</v-icon>
        <div class="jc-ticket__tag-name">{{ ticket.type }}</div>
      </div>
      <div class="jc-ticket__title">
        <div class="jc-ticket__title-status">{{ ticket.status }}</div>
        <div class="jc-ticket__title-text">{{ ticket.summary }}</div>
      </div>
      <div class="jc-ticket__desc">{{ ticket.description }}</div>
    </div>
    <div class="jc-ticket__col-2">
      <div class="jc-ticket__avatar">
        <v-icon color="primary" size="24">mdi-account-outline</v-icon>
      </div>
      <div class="jc-ticket__comments">{{ commentsAmount }}</div>
    </div>
  </div>
</template>

<script>
import { icons } from '@/form'

export default {
  name: 'JcTicet',

  props: {
    ticket: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      icons
    }
  },

  computed: {
    commentsAmount () {
      if (this.ticket.comments) {
        return this.ticket.comments.length
      }
      return 0
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-ticket
  padding: 25px
  display: grid
  grid-template-columns: auto 100px
  border-radius: 15px
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)
  max-width: 900px

  @media (max-width: 600px) {
    grid-template-columns: auto
  }

  &__col-2
    display: flex
    flex-direction: column
    align-items: flex-end
    justify-content: space-between

    @media (max-width: 600px) {
      flex-direction: row
      margin-top: 16px
    }

  &__tag
    display: flex
    align-items: center
    marign-bottom: 5px

  &__tag-name
    text-transform: uppercase
    font-weight: 900
    font-size: 14px
    color: var(--c-secondary)
    margin-left: 5px

  &__title-status
    display: inline-block
    font-size: 14px
    text-transform: uppercase
    color: var(--c-primary)
    padding-bottom: 1px
    border-bottom: 1px solid var(--c-primary)
    margin-left: 5px

  &__title-text
    font-weight: 500
    font-size: 30px
    margin-left: 5px
    color: var(--c-primary)

  &__avatar
    display: flex
    justify-content: center
    align-items: center
    width: 50px
    height: 50px
    border-radius: 3px
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)

  &__comments
    display: flex
    justify-content: center
    align-items: center
    width: 50px
    height: 50px
    background-color: var(--c-primary)
    border-radius: 3px
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)
    color: var(--c-white)
</style>
