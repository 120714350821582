import rules from './rules'

export default {
  website_tagging: [
    { id: 'opu', label: 'OPU', defaultValue: null, field: 'v-autocomplete', fieldItems: 'opus', rules: [rules.required], required: true },
    { id: 'business_segment', label: 'Business Segment', defaultValue: null, field: 'v-autocomplete', fieldItems: 'business_segmentations', rules: [rules.required], required: true },
    { id: 'indication', label: 'Indication', defaultValue: null, field: 'jc-autocomplete', fieldItems: 'indications', rules: [rules.required], required: true },
    { id: 'brand', label: 'Brand', defaultValue: null, field: 'jc-autocomplete', fieldItems: 'brands', rules: [rules.required], required: true },
    { id: 'website_localized', label: 'Website localized', defaultValue: null, field: 'v-autocomplete', fieldItems: 'countries', rules: [], required: false },
    { id: 'url_prod', label: 'URL (PROD/final)', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.url], required: true, hint: 'Please share the final URL of the Website.' },
    { id: 'url_staging', label: 'URL (QA/staging)', defaultValue: null, field: 'v-text-field', rules: [rules.url], required: false, hint: 'If available, please share the staging/QA/testing URL of the website.' },
    { id: 'username', label: 'Username', defaultValue: null, field: 'v-text-field', rules: [], required: false, hint: 'If the website is protected with a Login, please provide username to be able to review the website.' },
    { id: 'password', label: 'Password', defaultValue: null, field: 'jc-password', rules: [], required: false, hint: 'If the website is protected with a Login, please provide password to be able to review the website.' },
    { id: 'date_live', label: 'Planned go live date', defaultValue: null, field: 'jc-date-picker', rules: [rules.required], required: true },
    { id: 'personal_email_address', label: 'Personal email address', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.email], required: true },
    { id: 'website_objective', label: 'Website Objective', defaultValue: null, field: 'v-text-field', rules: [rules.required], required: true },
    { id: 'website_target', label: 'Website Target', defaultValue: null, field: 'v-text-field', rules: [rules.required], required: true },
    { id: 'website_features', label: 'Website Features', defaultValue: null, field: 'v-autocomplete', multiple: true, fieldItems: 'website_features', rules: [rules.required], required: true, hint: 'Please select planned website components so that we can propose the best website tracking solution.' },
    { id: 'website_features_other', label: 'Other Features', defaultValue: null, field: 'v-textarea', rules: [], required: false, hint: 'If your feature isnt listed please describe it' },
    { id: 'performance_indicator', label: 'Performance Indicator', defaultValue: null, field: 'v-text-field', rules: [], required: false },
    { id: 'description', label: 'Description', defaultValue: null, field: 'v-text-field', rules: [], required: false },
    { id: 'attachments', label: 'Attachment', defaultValue: null, field: 'jc-file-picker', rules: [], required: false }
  ],
  advertising_snippet: [
    { id: 'opu', label: 'OPU', defaultValue: null, field: 'v-autocomplete', fieldItems: 'opus', rules: [rules.required], required: true },
    { id: 'url_prod', label: 'URL (PROD/final)', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.url], required: true, hint: 'Please share the final URL of the Website.' },
    { id: 'snippet_logic', label: 'Snippet Logic', defaultValue: null, field: 'v-text-field', rules: [rules.required], required: true, hint: 'Please provide further information of the requested snippet. Additionally describe this marketing snippet logic, e.g. for which action it shall be triggered' },
    { id: 'snippet_code', label: 'Snippet Code', defaultValue: null, field: 'jc-codearea', rules: [rules.required], required: true, hint: 'Please provide the Code Snippet for the described marketing tag.' },
    { id: 'date_live', label: 'Planned go live date', defaultValue: null, field: 'jc-date-picker', rules: [], required: false },
    { id: 'personal_email_address', label: 'Personal email address', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.email], required: true },
    { id: 'description', label: 'Description', defaultValue: null, field: 'v-text-field', rules: [], required: false },
    { id: 'attachments', label: 'Attachment', defaultValue: null, field: 'jc-file-picker', rules: [], required: false }
  ],
  bug: [
    { id: 'opu', label: 'OPU', defaultValue: null, field: 'v-autocomplete', fieldItems: 'opus', rules: [rules.required], required: true },
    { id: 'url_prod', label: 'URL (PROD/final)', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.url], required: true, hint: 'Please share the final URL of the Website.' },
    { id: 'description', label: 'Bug description', defaultValue: null, field: 'v-textarea', rules: [rules.required], required: true, hint: 'Please describe the bug in detail - when, where, how exactly does the bug occur.' },
    { id: 'personal_email_address', label: 'Personal email address', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.email], required: true },
    { id: 'attachments', label: 'Attachment', defaultValue: null, field: 'jc-file-picker', rules: [], required: false }
  ],
  support: [
    { id: 'opu', label: 'OPU', defaultValue: null, field: 'v-autocomplete', fieldItems: 'opus', rules: [rules.required], required: true },
    { id: 'url_prod', label: 'URL (PROD/final)', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.url], required: true, hint: 'Please share the final URL of the Website.' },
    { id: 'description', label: 'Support description', defaultValue: null, field: 'v-textarea', rules: [rules.required], required: true, hint: 'Please shortly describe your support request.' },
    { id: 'personal_email_address', label: 'Personal email address', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.email], required: true },
    { id: 'attachments', label: 'Attachment', defaultValue: null, field: 'jc-file-picker', rules: [], required: false }
  ],
  adobe_analytics_account: [
    { id: 'opu', label: 'OPU', defaultValue: null, field: 'v-autocomplete', fieldItems: 'opus', rules: [rules.required], required: true },
    { id: 'url_prod', label: 'URL (PROD/final)', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.url], required: true },
    { id: 'personal_email_address', label: 'Personal email address', defaultValue: null, field: 'v-text-field', rules: [rules.required, rules.email], required: true },
    { id: 'adobe_analytics', label: 'Adobe Analytics', defaultValue: false, field: 'v-checkbox', rules: [], required: false },
    { id: 'adobe_target', label: 'Adobe Target', defaultValue: false, field: 'v-checkbox', rules: [], required: false }
  ],
  cid_value: [
    { id: 'opu', label: 'OPU', defaultValue: null, field: 'v-autocomplete', fieldItems: 'opus', rules: [rules.required], required: true },
    { id: 'campaign_type', label: 'Campaign Type', defaultValue: null, field: 'v-text-field', rules: [rules.required], required: true },
    { id: 'media_type', label: 'Media Type', defaultValue: null, field: 'v-text-field', rules: [rules.required], required: true },
    { id: 'media_placement', label: 'Media Placement', defaultValue: null, field: 'v-text-field', rules: [rules.required], required: true },
    { id: 'target_audience', label: 'Target Audience', defaultValue: null, field: 'v-text-field', rules: [], required: false },
    { id: 'ad_type', label: 'Ad Type', defaultValue: null, field: 'v-text-field', rules: [], required: false },
    { id: 'ad_size', label: 'Ad Size', defaultValue: null, field: 'v-text-field', rules: [], required: false }
  ]
}
