<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="8">
          <v-card v-if="step == $options.STEP_ENTER_PASSWORD" class="jc-create-card">
            <v-card-title>Reset password</v-card-title>
            <v-card-subtitle>Enter your new password below</v-card-subtitle>
            <v-card-text>
              <v-form v-model="valid" @submit.prevent="onSaveClick">
                <jc-password
                  v-model="password"
                  label="Password"
                  :rules="[rules.required, rules.password]"
                  required
                  autocomplete="off"
                />
                <jc-password
                  v-model="confirmPassword"
                  label="Confirm Password"
                  :rules="[rules.required, rules.password, rules.match(password)]"
                  required
                  autocomplete="off"
                />
                <div class="jc-create-card__btns">
                  <jc-btn type="submit" :disabled="!valid || inProgress" :loading="inProgress">Save</jc-btn>
                  <jc-btn color="secondary" :disabled="inProgress" @click="$router.back()">Back</jc-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card v-else-if="step == $options.STEP_INFO" class="jc-create-card">
            <v-card-title>Reset password</v-card-title>
            <v-card-text>
              Your password has been successfully changed.
            </v-card-text>
            <div class="jc-reset-card__btns">
              <jc-btn color="secondary" @click="$router.push('/login')">Login</jc-btn>
            </div>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import JcBtn from '@/components/JcBtn'
import { rules } from '@/form'
import JcPassword from '@/components/JcPassword'

const STEP_ENTER_PASSWORD = 1
const STEP_INFO = 2

var email = ''
var token = ''

export default {
  components: { JcBtn, JcPassword },

  name: 'ResetPassword',

  STEP_ENTER_PASSWORD,
  STEP_INFO,

  data: () => ({
    inProgress: false,
    rules: rules,
    valid: false,
    password: null,
    confirmPassword: null,
    email: '',
    step: STEP_ENTER_PASSWORD
  }),

  created () {
    email = this.$route.query.email
    token = this.$route.query.token

    if (email === '' || token === '') {
      this.$router.push('/login')
    }

    this.$router.replace({ query: {} })
  },

  methods: {
    ...mapActions('account', ['resetPassword']),

    onSaveClick () {
      if (!this.valid) return
      this.inProgress = true

      this.resetPassword({ email: email, password: this.password, token: token }).then(() => {
        this.inProgress = false
        this.step = STEP_INFO
      }).catch((err) => {
        this.inProgress = false
        this.$notify({
          type: 'error',
          title: 'Reset failed',
          text: err.message ?? err
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-create-card
  &__btns
    display: flex
    justify-content: flex-end
    align-items: center
</style>
