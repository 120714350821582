<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container class="jc-choose-ticket">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <router-link :to="{ name: 'New Website Tagging' }">
            <jc-icon-btn :icon="icons.website_tagging" flip>
              <template slot="default">
                New Website Tagging
              </template>
              <template slot="desc">
                To add a new website to BI's tag manager, InstaTag, this form must be completed. The provided information will help us to suggest the best web tracking solution and allow us to add the website to Instatag.
              </template>
            </jc-icon-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <router-link :to="{ name: 'Advertising Snippet' }">
            <jc-icon-btn :icon="icons.advertising_snippet" flip>
              <template slot="default">
                Advertising Snippet
              </template>
              <template slot="desc">
                All marketing tags are supposed do be added through BI's tag manager, Insta Tag. Please provide all relevant information, such as the URL, as well as the marketing snippet code, so that the requested tag can be implemented.
              </template>
            </jc-icon-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <router-link :to="{ name: 'Bug Request' }">
            <jc-icon-btn :icon="icons.bug" flip>
              <template slot="default">
                Bug Request
              </template>
              <template slot="desc">
                If errors are noticed in relation to Adobe Analytics implementation, marketing tags implementation or reporting (Adobe Analytics, Tableau Digital Performance Management), please describe them here.
              </template>
            </jc-icon-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <router-link :to="{ name: 'Support Request' }">
            <jc-icon-btn :icon="icons.support" flip>
              <template slot="default">
                Support Request
              </template>
              <template slot="desc">
                If questions arise or support is needed related to deliverables of Digital Analytics Capability, such as web analytics, please describe them here.
              </template>
            </jc-icon-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <router-link :to="{ name: 'Adobe Account Request' }">
            <jc-icon-btn :icon="icons.adobe_analytics_account" flip>
              <template slot="default">
                Adobe Account Request
              </template>
              <template slot="desc">
                Please provide your personal information in order to get access to Adobe Analytics/ Adobe Target
              </template>
            </jc-icon-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <router-link :to="{ name: 'CID Value Request' }">
            <jc-icon-btn :icon="icons.cid_value" flip>
              <template slot="default">
                CID Value Request
              </template>
              <template slot="desc">
                Please provide the missing value and the corresponding media type, to finalize your request
              </template>
            </jc-icon-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import JcIconBtn from '@/components/JcIconBtn'
import { icons } from '@/form'

export default {
  name: 'CreateTicket',
  components: { JcIconBtn },
  data () {
    return {
      icons
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-choose-ticket
  /deep/ .jc-icon-btn
    &__text
      font-size: 28px
</style>
