<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container>
      <v-row justify="center">
        <v-overlay
          :absolute="true"
          :value="processing"
        >
          <v-progress-circular
            v-if="processing"
            indeterminate
            color="primary"
            :style="{
              position: 'fixed',
              top: '50vh',
              left: '50vw',
              transform: 'translate(-50%, -50%)'
            }"
          />
        </v-overlay>
        <v-col cols="12" sm="8">
          <jc-form @submit="submit" :processing="processing">
            <input name="type" type="hidden" :value="type" />
            <template v-for="field in fields">
              <component
                :key="field.id"
                :is="field.field"
                :label="field.label"
                v-model="$data[field.id]"
                :items="getFieldItems(field.fieldItems)"
                :field-items="field.fieldItems"
                item-text="label"
                :required="field.required"
                :rules="customRules(field)"
                :hint="field.hint"
                :multiple="field.multiple"
                persistent-hint
                :autocomplete="field.field === 'jc-password' ? 'new-password' : ''"
              />
            </template>
          </jc-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VTextField, VAutocomplete, VCheckbox, VTextarea } from 'vuetify/lib'
import JcForm from '@/components/JcForm'
import JcDatePicker from '@/components/JcDatePicker'
import JcPassword from '@/components/JcPassword'
import JcFilePicker from '@/components/JcFilePicker'
import { forms } from '@/form'
import JcCodearea from '@/components/JcCodearea'
import JcAutocomplete from '@/components/JcAutocomplete'

export default {
  name: 'TicketRequest',

  /* eslint-disable vue/no-unused-components */
  components: { JcForm, VTextField, VAutocomplete, VCheckbox, JcFilePicker, JcDatePicker, JcPassword, VTextarea, JcCodearea, JcAutocomplete },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      processing: false,
      ...this.getFieldsData()
    }
  },

  computed: {
    ...mapGetters('formFields', ['getFieldItems']),

    fields () {
      return forms[this.type] ?? []
    }
  },

  methods: {
    ...mapActions('tickets', ['addNewTicket', 'attachFileToTicket']),

    submit () {
      if (this.processing) {
        return
      }
      this.processing = true

      const data = Object.fromEntries(Object.entries(this.$data).filter(item => item[1] != null))
      data.type = this.type

      this.addNewTicket(data).then((ticket) => {
        if (this.$data.attachments && this.$data.attachments.length) {
          // Upload file
          return this.attachFileToTicket({
            ticket: ticket,
            attachment: this.$data.attachments
          }).catch(() => {
            this.processing = false
            this.$notify({
              type: 'error',
              title: 'Error',
              text: 'Creating ticket succeeded, but the attachment upload failed'
            })
          })
        } else {
          this.processing = false
        }
      }).then(() => {
        this.processing = false
        this.$router.push('/ticket-created')
      }).catch(() => {
        this.processing = false
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Creating ticket failed'
        })
      })
    },

    getFieldsData () {
      const fields = forms[this.type] ?? []
      return fields.reduce((obj, field) => {
        obj[field.id] = field.defaultValue
        return obj
      }, {})
    },

    customRules (field) {
      switch (field.id) {
        case 'website_features':
          return this.website_features_other ? [] : field.rules
        default:
          return field.rules
      }
    }
  }
}
</script>

<style>

</style>
