<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container class="jc-intro">
      <v-row>
        <v-col>
          <v-img class="jc-intro__logo" src="@/assets/images/ah-logo.png" alt="AH Logo" />
          <div class="jc-intro__title mb-4">AH Global Digital Website Tagging</div>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="6" md="4">
          <router-link :to="{ name: 'Create ticket' }">
            <jc-icon-btn icon="mdi-pencil-outline">Create ticket</jc-icon-btn>
          </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <router-link :to=" { name: 'List of tickets' }">
            <jc-icon-btn icon="mdi-clipboard-list-outline">List of tickets</jc-icon-btn>
          </router-link>
        </v-col>
        <v-col v-if="isAdmin" cols="12" sm="6" md="4">
          <router-link :to="{ name: 'Accounts' }">
            <jc-icon-btn icon="mdi-account-outline">Accounts</jc-icon-btn>
          </router-link>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import JcIconBtn from '@/components/JcIconBtn'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: { JcIconBtn },
  computed: {
    ...mapGetters('session', ['isAdmin'])
  }
}
</script>

<style lang="stylus" scoped>
.jc-intro
  &__logo
    max-width: 300px

  &__title
    font-size: 36px
    color: var(--c-primary)
    font-weight: bold
</style>
