<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
          />
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-overlay
          :absolute="true"
          :value="inProgress"
        >
          <v-progress-circular
            v-if="inProgress"
            indeterminate
            color="primary"
          />
        </v-overlay>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="accounts"
            :search="search"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.is_active`]="{ item }">
              <v-switch :input-value="item.is_active" @change="fieldChanged('is_active', item)" />
            </template>
            <template v-slot:[`item.is_admin`]="{ item }">
              <v-switch :input-value="item.is_admin" @change="fieldChanged('is_admin', item)" />
            </template>
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <jc-edit-account :item="item" />
            </template> -->
          </v-data-table>
          <v-pagination
            v-if="pageCount > 1"
            v-model="page"
            :length="pageCount"
            class="mt-4"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// import JcEditAccount from '@/components/JcEditAccount.vue'

export default {
  name: 'Accounts',

  // components: { JcEditAccount },

  data: () => ({
    search: null,
    page: 1,
    pageCount: 0,
    inProgress: false,
    absolute: true,
    headers: [
      {
        text: 'First Name',
        value: 'firstname'
      },
      {
        text: 'Last Name',
        value: 'lastname'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Country',
        value: 'country_iso2'
      },
      {
        text: 'Created at',
        value: 'created_at'
      },
      {
        text: 'Is Active',
        value: 'is_active'
      },
      {
        text: 'Is Admin',
        value: 'is_admin'
      }
      // {
      //   text: 'Actions',
      //   value: 'actions',
      //   sortable: false,
      //   align: 'center'
      // }
    ]
  }),

  created () {
    this.inProgress = true
    this.getAccounts().then(() => {
      this.inProgress = false
    })
  },

  computed: {
    ...mapGetters('account', ['accounts'])
  },

  methods: {
    ...mapActions('account', ['getAccounts', 'toggle']),

    fieldChanged (fieldName, account) {
      this.inProgress = true

      this.toggle({
        account: account,
        field: fieldName
      }).then(() => {
        this.inProgress = false
      }).catch((err) => {
        this.inProgress = false
        this.$notify({
          type: 'error',
          title: 'Update failed',
          text: err && err.message ? err.message : err
        })
      })
    }
  }
}
</script>

<style>

</style>
