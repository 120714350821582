<template>
  <div @click="download">
    <v-progress-circular
      v-if="loader"
      indeterminate
      color="primary"
      size="24"
    />
    <v-icon
      v-else
      color="primary"
      size="24"
    >
      mdi-download
    </v-icon>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'JcDownloadBtn',
  props: {
    ticket: {
      required: true
    },
    file: {
      required: true
    }
  },
  data () {
    return {
      loader: false
    }
  },
  methods: {
    ...mapActions('tickets', ['downloadAttachment']),
    async download () {
      this.loader = true
      await this.downloadAttachment({ ticket: this.ticket, fileSpecs: this.file })
      this.loader = false
    }
  }
}
</script>

<style>

</style>
