<template>
  <div class="jc-page" :class="`jc-page--${$options.name}`">
    <v-container v-if="step === $options.STEP_SELECT_METHOD || step === $options.STEP_SSO">
      <v-row>
        <v-overlay
          :absolute="true"
          :value="inProgress"
        >
          <v-progress-circular
            v-if="inProgress"
            indeterminate
            color="primary"
          />
        </v-overlay>
        <v-col>
          <div class="jc-choose-login-form">
            <jc-icon-btn
              id="jc-login-external-user-btn"
              v-if="showExternalLoginButton"
              @click="step = $options.STEP_CREDENTIALS">
              <div>EXTERNAL USER</div>
              <div class="jc-choose-login-form__desc">Login or register as an external user</div>
            </jc-icon-btn>
            <jc-icon-btn
              id="jc-login-internal-user-btn"
              @click="onSSOLoginClick">
              <div>BI EMPLOYEE</div>
              <div class="jc-choose-login-form__desc">Login or register as a BI employee</div>
            </jc-icon-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="step === $options.STEP_CREDENTIALS">
      <v-row>
        <v-spacer />
        <v-col
          cols="12"
          sm="8"
        >
          <v-card class="jc-login-card">
            <v-card-title>Sign in</v-card-title>
            <v-card-subtitle>Please login</v-card-subtitle>
            <v-card-text>
              <v-form v-model="valid" @submit.prevent="onLoginClick">
                <v-text-field
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  label="Email"
                  type="email"
                  required
                />
                <jc-password
                  v-model="password"
                  label="Password"
                  :rules="[rules.required]"
                  required
                  autocomplete="on"
                />
                <div class="jc-login-card__btns my-4">
                  <jc-btn
                    type="submit"
                    :disabled="!valid || inProgress"
                    :loading="inProgress"
                  >Login</jc-btn>
                  <jc-btn color="secondary" @click="step = $options.STEP_SELECT_METHOD">Back</jc-btn>
                </div>
              </v-form>
            </v-card-text>
            <v-card-text class="jc-login-card__optional-btns">
              <router-link :to="{ name: 'Register' }"><jc-btn color="secondary">Create a new account</jc-btn></router-link>
              <router-link :to="{ name: 'Forgot password' }"><jc-btn color="secondary">Forgot password</jc-btn></router-link>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JcBtn from '@/components/JcBtn.vue'
import JcPassword from '@/components/JcPassword.vue'
import { rules } from '@/form'
import JcIconBtn from '@/components/JcIconBtn'
import { isRMHUiEnabled } from '../utils/common'

const STATUS_OK = '200'
const STATUS_SERVER_ERROR = '500'
const STATUS_FORBIDDEN = '403'
const STATUS_UNAUTHORIZED = '401'
const STATUS_FAILED_DEPENDENCY = '424'

const STEP_SELECT_METHOD = 1
const STEP_CREDENTIALS = 2
const STEP_SSO = 3

export default {
  components: {
    JcBtn,
    JcPassword,
    JcIconBtn
  },

  name: 'Login',

  data: () => ({
    rules: rules,
    valid: false,
    inProgress: false,
    email: null,
    password: null,
    showPassword: false,
    step: STEP_SELECT_METHOD,
    showExternalLoginButton: isRMHUiEnabled()
  }),

  STEP_SELECT_METHOD,
  STEP_CREDENTIALS,
  STEP_SSO,

  loginErrors: {
    INACTIVE: 'Cannot log in. Your accout is inactive, please contact an admin.',
    UNKNOWN: 'Unknown account. Please make sure that you have entered valid credentials',
    BACKEND: 'Logind failed. Seems like something is wrong on backend side',
    COMMON: 'Login failed. Contact an admin for details.'
  },

  created () {
    const status = this.$route.query.status
    const token = this.$route.query.token

    switch (status) {
      case STATUS_FAILED_DEPENDENCY:
      case STATUS_OK: {
        this.step = STEP_SSO
        this.inProgress = true
        this.message = this.$route.query.success
        this.$router.replace({ query: {} })
        this.loginWithToken({ token }).then(() => {
          this.$router.push('/')
        }).catch((err) => {
          if (err.message === '424') {
            this.$router.push('/register')
            return
          }

          if (err.message === '403') {
            this.$router.push('/forbidden')
            return
          }

          this.$notify({
            type: 'error',
            title: 'Login failed',
            text: err.message ?? err
          })
        }).finally(() => {
          this.inProgress = false
        })
        break
      }
      case STATUS_FORBIDDEN:
      case STATUS_UNAUTHORIZED:
      case STATUS_SERVER_ERROR: {
        this.inProgress = false
        this.step = STEP_SSO
        this.$notify({
          type: 'error',
          title: 'Server error',
          text: this.$route.query.error || this.$options.loginErrors.COMMON
        })
        break
      }
      default: {
        break
      }
    }
  },

  computed: {
    ...mapGetters('session', ['isActive'])
  },

  methods: {
    ...mapActions('session', ['loginWithPassword', 'loginWithToken']),

    onSSOLoginClick () {
      this.inProgress = true
      this.step = STEP_SSO
      const refererUrl = encodeURIComponent(process.env.VUE_APP_APP_URL + '/login')
      document.location.href = process.env.VUE_APP_AUTH_SERVICE_LOGIN_URL + '?referer=' + refererUrl
    },

    onLoginClick () {
      if (!this.valid) return
      this.inProgress = true

      this.loginWithPassword({ email: this.email, password: this.password }).then(() => {
        this.inProgress = false
        this.$router.push('/')
      }).catch((err) => {
        this.inProgress = false
        this.$notify({
          type: 'error',
          title: 'Login failed',
          text: err.message ?? err
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.jc-choose-login-form
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: center

  &__desc
    font-size: 14px
    text-transform: none

  /deep/ .jc-icon-btn
    width: 300px
    height: 225px
    min-height: auto
    margin: 0 16px

.jc-login-card
  &__btns
    display: flex
    justify-content: flex-end
    align-items: center

  &__optional-btns
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center

    /deep/ .jc-btn
      font-size: 14px !important
</style>
